<template>
  <v-container v-cloak>
    <v-row>
      <v-breadcrumbs :items='bread' large></v-breadcrumbs>
    </v-row>
    <v-row>
        <v-col>
            <CuotasAVencer></CuotasAVencer>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CuotasAVencer from '@/components/Dashboard/CuotasAVencer.vue';

export default {
  name: 'Cuotas',
  components: {
    CuotasAVencer,
  },
  data: () => ({
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Cuotas a Vencer',
        disabled: true,
        href: '/',
      },
    ],
  }),
};
</script>
