<template>
<div>
<h2 class="mt-5">Cuotas a Vencer este mes</h2>
<v-row>
    <v-col cols = 3>
      <v-text-field
        label="Filtrar Nº Legajo"
        v-model="filter.nro_legajo"
      >
      </v-text-field>
    </v-col>
    <v-col cols="1">
      <v-btn @click="filterDataTable"
      color="primary">
            <v-icon v-if="!filter.loading">mdi-filter-outline</v-icon>
            <v-progress-circular
              v-if="filter.loading"
              :size="25"
              color="white"
              indeterminate
            ></v-progress-circular>
      </v-btn>
    </v-col>
  </v-row>
<v-btn color="primary" :disabled="!isSelected"
:loading="loadingSend"
@click="enviarCuotaTrilay">
  Generar Cargos
</v-btn>
<br>
<br>
<v-alert
  dense
  dismissible
  elevation="4"
  outlined
  text
  type="warning">
  Solo se mostraran las cuotas pertenecientes a Clientes
  sincronizados correctamente en Trilay (Email y Cuit/Cuil)
</v-alert>
<v-card>
  <v-data-table
    :headers="headers"
    show-select
    v-model="selected"
    :items="cuotas"
    :items-per-page="10"
    :loading="loading"
    item-key="id"
    loading-text="Obteniendo cuotas..."
    :search='search'
    class="elevation-1"
    no-data-text='No se han cargado datos aun'
    no-results-text='No hay resultados'
    :item-class="isVencida"
  >
   <template v-slot:top>
          <v-text-field
            v-model='search'
            label='Buscar'
            class='mx-4'
          ></v-text-field>
        </template>
  </v-data-table>
  </v-card>
</div>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  name: 'CuotasAVencer',
  data: () => ({
    selected: [],
    search: '',
    headers: [
      {
        text: 'Cliente',
        align: 'start',
        sortable: true,
        value: 'cliente',
      },
      {
        text: 'N° Legajo',
        align: 'start',
        sortable: true,
        value: 'lineacreditocliente_id',
      },
      {
        text: 'N° Cuota',
        align: 'start',
        sortable: true,
        value: 'ncuota',
      },
      {
        text: 'Vencimiento',
        align: 'start',
        sortable: true,
        value: 'fecha',
      },
      {
        text: 'Intereses',
        align: 'start',
        sortable: true,
        value: 'intereses',
      },
    ],
    filter: {
      nro_legajo: '',
      loading: false,
    },
    list_tipo_operatoria: [],
    selAllCheck: false,
    loading: false,
    loadingSend: false,
    cuotas: [],
  }),
  computed: {
    isSelected() {
      let aux = false;
      if (this.selected.length > 0) {
        aux = true;
      }
      return aux;
    },
  },
  methods: {
    isVencida(item) {
      // eslint-disable-next-line
      let rowClass = '';
      if (item.vencida) {
        rowClass = 'row-vencida';
      }
      return rowClass;
    },
    async filterDataTable() {
      this.getCuotas(this.filter.nro_legajo);
    },
    selAll(array) {
      /* eslint-disable no-plusplus */
      for (let index = 0; index < array.length; index++) {
        const c = array[index];
        c.sel = this.selAllCheck;
      }
    },
    async enviarCuotaTrilay() {
      this.loadingSend = true;
      const query = {
        method: 'POST',
        url: `${API_URL}api/trilay/cargos`,
        data: {
          cuotas: this.selected,
        },
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios(query).then((result) => {
        if (result.data.status === 'success') {
          this.cuotas = this.cuotas.filter((c) => !this.selected.includes(c));
          this.selected = [];
          this.$swal.fire('Trilay', 'Cargos generados con exito', 'success');
        } else {
          this.$swal.fire('Trilay', result.data.message, 'error');
        }
      }).catch((error) => {
        // eslint-disable-next-line
        console.error(error);
        this.loadingSend = false;
        this.$swal.fire('Trilay', 'Ocurrió un error', 'error');
      });
      this.loadingSend = false;
    },
    async getCuotas(lccid = null) {
      this.loading = true;
      let url = `${API_URL}api/vencimientos/cuotas`;
      if (lccid !== null) {
        url = `${API_URL}api/vencimientos/cuotas/${lccid}`;
      }
      const query = {
        method: 'get',
        url,
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios(query).then((result) => {
        if (result.data.status === 'success') {
          this.cuotas = result.data.cuotas;
        } else {
          this.$swal.fire('Error', result.data.message, 'error');
        }
        this.loading = false;
      }).catch((error) => {
        this.$swal.fire('Cuotas', 'Ocurrió un error al obtener el listado', 'error');
        // eslint-disable-next-line
        console.error(error);
        this.loading = false;
      });
    },
  },
  created() {
    this.getCuotas();
  },
};
</script>
<style>
tr.row-vencida td{
  color: red;
}
</style>
